<template>
    <div class="Employee_Information_add_success">
        <div class="top_title">入职登记</div>
        <div class="success_img">
            <img src="../../assets/img/employee/success.png" alt="">
        </div>
        <div class="success_text">登记成功</div>
        <div class="btn_flex">
            <div class="btns1" @click="handle_ckxq">查看详情</div>
            <div class="btns2" @click="handle_xxdj">继续登记</div>
        </div>
            
            
    </div>
</template>
<script>

export default {
    name: "Customer_Information_form",
    
    data() {
        return {
           
          

        };
    },
    methods: {
        handle_ckxq(){
            this.$router.push({
                path:'/Employee_Information_detial',
                query:{
                    id:this.$route.query.id
                }
            })
        },
        handle_xxdj(){
            this.$router.push({
                path:'/Employee_Information_add',
            })
        },
         
    },
    computed: {
    },

    watch: {
        
    },


    created() {


    },


};
</script>

<style scoped lang="less">
.Employee_Information_add_success {
    width: 1640px;
    height: 100%;
    background: #FFFFFF;
    border-radius: 8px 8px 8px 8px;
    border: 1px solid #E6E6E6;
    padding: 19px 17px;
    .top_title{
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: bold;
        font-size: 14px;
        color: #333333;
    }
   .success_img{
        padding-top: 150px;
        text-align: center;
        img{
            width: 78px;
            height: 78px;
            border-radius: 50%;
        }
    }
    .success_text{
        text-align: center;
        padding-top: 25px;
        font-family: Microsoft YaHei, Microsoft YaHei;
        font-weight: 400;
        font-size: 24px;
        color: #000000;
    }
    .btn_flex{
        display: flex;
        align-items: center;
        justify-content: center;
        padding-top: 50px;
        .btns1{
            width: 128px;
            height: 40px;
            background: #2373C8;
            text-align: center;
            line-height: 40px;
            border-radius: 8px 8px 8px 8px;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #FFFFFF;
            cursor: pointer;

        }
        .btns2{
            width: 128px;
            height: 40px;
            border: 1px solid #2373C8;
            border-radius: 8px 8px 8px 8px;
            cursor: pointer;
            font-family: Microsoft YaHei, Microsoft YaHei;
            font-weight: 400;
            font-size: 14px;
            color: #2373C8;
            line-height: 40px;
            text-align: center;
            margin-left: 25px;
        }
    }
     
}
</style>